import React, { useEffect } from "react";
import "./Header.css";
// import Button1 from "../utilities/Button1";
import Aos from "aos";
import "aos/dist/aos.css";

function Header() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <header className="header">
      <div className="banner">
        <h1 className="banner-heading" data-aos="fade-up" data-aos-delay="700">
          Witamy w Relaxarium
        </h1>
        <p
          className="banner-paragraph"
          data-aos="fade-down"
          data-aos-delay="1200"
        >
          Serdecznie zapraszam do Relaxarium miejsca stworzonego w 2007 roku w
          sercu tętniącego życiem Starego Miasta we Wrocławiu. Gabinet powstał z
          myślą o osobach ceniących komfort, kameralną atmosferę i indywidualne
          podejście do każdego klienta. W Relaxarium czas zwalnia.
        </p>
        <p className="owner" data-aos="fade-right" data-aos-delay="1500">Izabela Skalska</p>
      </div>
    </header>
  );
}

export default Header;
