import React from "react";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import im1 from "../../img/image1.jpeg";
import im2 from "../../img/image2.jpeg";
import im3 from "../../img/image3.jpeg";
import im4 from "../../img/image4.jpeg";
import im5 from "../../img/image5.jpeg";
import im1_thmbn from "../../img/image1-thumbnail.jpg";
import im2_thmbn from "../../img/image2-thumbnail.jpg";
import im3_thmbn from "../../img/image3-thumbnail.jpg";
import im4_thmbn from "../../img/image4-thumbnail.jpg";
import im5_thmbn from "../../img/image5-thumbnail.jpg";

function PhotoSwipGallery() {
  return (
    <Gallery>
      <Item original={im1} thumbnail={im1_thmbn} width="768" height="1024">
        {({ ref, open }) => <img ref={ref} onClick={open} src={im1_thmbn} />}
      </Item>
      <Item original={im2} thumbnail={im2_thmbn} width="768" height="1024">
        {({ ref, open }) => <img ref={ref} onClick={open} src={im2_thmbn} />}
      </Item>
      <Item original={im3} thumbnail={im3_thmbn} width="768" height="1024">
        {({ ref, open }) => <img ref={ref} onClick={open} src={im3_thmbn} />}
      </Item>
      <Item original={im4} thumbnail={im4_thmbn} width="768" height="1024">
        {({ ref, open }) => <img ref={ref} onClick={open} src={im4_thmbn} />}
      </Item>
      <Item original={im5} thumbnail={im5_thmbn} width="768" height="1024">
        {({ ref, open }) => <img ref={ref} onClick={open} src={im5_thmbn} />}
      </Item>
    </Gallery>
  );
}

export default PhotoSwipGallery;
