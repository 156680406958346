import React, { useEffect } from "react";
import classes from "./Service.module.css";
import PropTypes from "prop-types";
import Aos from "aos";
import "aos/dist/aos.css";
import PopupPage from "./PopupPage";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function Service({ number, name, imageSrc, description, productType }) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const { width } = useWindowDimensions();

  return (
    <section className={classes.card} data-aos="fade" data-aos-once="true">
      {width > 400 && (
        <div className={classes.split}>
          {number % 2 == 0 ? (
            <div className={classes.imgWrap}>
              <img className={classes.serviceImg} src={imageSrc} alt={name} />
            </div>
          ) : (
            <div className={classes.description}>
              <h1>{name}</h1>
              <p>{description}</p>
              <PopupPage
                title={name}
                imageSrc={imageSrc}
                productType={productType}
              />
            </div>
          )}
          {number % 2 != 0 ? (
            <div className={classes.imgWrap}>
              <img className={classes.serviceImg} src={imageSrc} alt={name} />
            </div>
          ) : (
            <div className={classes.description}>
              <h1>{name}</h1>
              <p>{description}</p>
              <PopupPage
                title={name}
                imageSrc={imageSrc}
                description={description}
                productType={productType}
              />
            </div>
          )}
        </div>
      )}
      {width < 400 && (
        <PopupPage
          title={name}
          imageSrc={imageSrc}
          description={description}
          productType={productType}
        />
      )}
    </section>
  );
}

export default Service;

Service.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  productType: PropTypes.string.isRequired,
};
