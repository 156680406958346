import classes from "./Services.module.css";
import React from "react";
import Service from "./Service";
import services from "../../data/services.json";

function Services() {
  return (
    <div className={classes.services}>
      <h1 className={classes.serviceHeader}>USŁUGI</h1>
      {services.flat().map((service, i) => {
        return (
          <Service
            key={i}
            name={service.name}
            imageSrc={service.imageSrc}
            description={service.description}
            number={i + 1}
            productType={service.productType}
          />
        );
      })}
    </div>
  );
}

export default Services;
