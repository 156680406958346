// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Services_services__TcJoz {\n  background-color: var(--color1);\n}\n.Services_serviceHeader__hBIY1 {\n  color: var(--color7);\n  font-family: var(--ff--primary);\n  font-size: 3rem;\n  font-weight: 400;\n  margin: 0;\n  padding: 2rem;\n}", "",{"version":3,"sources":["webpack://./src/components/services/Services.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;AACA;EACE,oBAAoB;EACpB,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,aAAa;AACf","sourcesContent":[".services {\n  background-color: var(--color1);\n}\n.serviceHeader {\n  color: var(--color7);\n  font-family: var(--ff--primary);\n  font-size: 3rem;\n  font-weight: 400;\n  margin: 0;\n  padding: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services": "Services_services__TcJoz",
	"serviceHeader": "Services_serviceHeader__hBIY1"
};
export default ___CSS_LOADER_EXPORT___;
