import React, { useEffect } from "react";
import classes from '../services/Services.module.css';
import offerStyle from './Offer.module.css'
import offers from '../../data/offers.json';
import Aos from "aos";
import "aos/dist/aos.css";

function Offers() {
    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
    console.log(offers);
    return (
      <div className={classes.services}>
        <h1 className={classes.serviceHeader}>OFERTA</h1>
        <section className={offerStyle.card} data-aos="fade" data-aos-once="true">
          {offers.flat().map((offer, i)=>{
              return (
                <div key={i}>
                  <span>{offer.title}</span>
                  <ul className={offerStyle.column}>  
                    {offer.sublist.flat().map((item, i) => {
                      return (              
                        <li className={offerStyle.offer} key={i} > 
                          {item.title}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )   
          })}
        </section>
      </div>
    );
  }
  
  export default Offers;
  