"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.shuffle = exports.sortNodes = exports.getElBounds = void 0;
var no_ref_error_1 = require("./no-ref-error");
var getElBounds = function (el) {
    var pageXScroll = window.pageXOffset || document.documentElement.scrollLeft;
    var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
    var rect = el.getBoundingClientRect();
    return {
        x: rect.left + pageXScroll,
        y: rect.top + pageYScroll,
        w: rect.width,
    };
};
exports.getElBounds = getElBounds;
function sortNodes(a, b) {
    if (!(a instanceof Element)) {
        throw new no_ref_error_1.NoRefError();
    }
    if (a === b)
        return 0;
    // eslint-disable-next-line no-bitwise
    if (a.compareDocumentPosition(b) & 2) {
        return 1;
    }
    return -1;
}
exports.sortNodes = sortNodes;
function shuffle(array) {
    var result = __spread(array);
    var currentIndex = result.length;
    var temp;
    var randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temp = result[currentIndex];
        // eslint-disable-next-line no-param-reassign
        result[currentIndex] = result[randomIndex];
        // eslint-disable-next-line no-param-reassign
        result[randomIndex] = temp;
    }
    return result;
}
exports.shuffle = shuffle;
