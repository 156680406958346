import React from "react";
import "./Sidebar.css";
import PropTypes from "prop-types";

function Sidebar({ pullLeftRight, handlerToggle }) {
  return (
    <div className="sidebar">
      <a onClick={pullLeftRight}>
        <div className="menu-icon">
          <div
            className={`line line-1 ${handlerToggle ? "closedLine1" : ""}`}
          ></div>
          <div
            className={`line line-2 ${handlerToggle ? "closedLine2" : ""}`}
          ></div>
          <div
            className={`line line-3 ${handlerToggle ? "closedLine3" : ""}`}
          ></div>
        </div>
      </a>
      <ul className="social-icons-list">
        <li>
          <a
            href="https://www.facebook.com/IzabelaSkalskaRelaxarium/"
            className="social-link"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a
            href="https://instagram.com/relaxarium_wroclaw/"
            className="social-link"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
Sidebar.propTypes = {
  pullLeftRight: PropTypes.func.isRequired,
  handlerToggle: PropTypes.bool.isRequired,
};
