import React, { useEffect } from "react";
import classes from "./Product.module.css";
import PropTypes from "prop-types";
import Aos from "aos";
import "aos/dist/aos.css";
import products from "../../data/products.json";

function Product({ productType }) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="container">
      {products[productType].flat().map((product, id) => {
        return (
          <section className={classes.card} key={id}>
            <div className={classes.split}>
              <div className={classes.imgWrap}>
                <img
                  className={classes.productImg}
                  src={product.image}
                  alt={product.name}
                />
              </div>
              <div className={classes.description}>
                <h2> {product.name.toUpperCase()} </h2>
                <div dangerouslySetInnerHTML={{ __html: product.description }} />
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
}

export default Product;
Product.propTypes = {
  productType: PropTypes.string.isRequired,
};
