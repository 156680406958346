// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "  .Offer_card__ohrZE {\n    \n    margin-inline: auto;\n    background-color: var(--color3);\n    padding: 2rem;\n    color: var(--color2);\n    text-align: left;\n    align-content: centers;\n  }\n  .Offer_offer__EaBvD{\n    margin-bottom: 2rem;\n    font-family: var(--ff--accent);\n  }\n  .Offer_column__yUXjy {\n    columns: 2;\n    margin-inline: auto;\n    width: min(90%, 90rem);\n  }\n\n\n  @media (max-width: 1040px) {\n    .Offer_column__yUXjy {\n      display: flex;\n      flex-direction: column;\n    }\n    .Offer_offer__EaBvD{\n      margin: 0.7rem 0;\n    }\n  }\n  @media (max-width : 400px) {\n    .Offer_offer__EaBvD{\n      font-size: .8rem;\n    }\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/offers/Offer.module.css"],"names":[],"mappings":"EAAE;;IAEE,mBAAmB;IACnB,+BAA+B;IAC/B,aAAa;IACb,oBAAoB;IACpB,gBAAgB;IAChB,sBAAsB;EACxB;EACA;IACE,mBAAmB;IACnB,8BAA8B;EAChC;EACA;IACE,UAAU;IACV,mBAAmB;IACnB,sBAAsB;EACxB;;;EAGA;IACE;MACE,aAAa;MACb,sBAAsB;IACxB;IACA;MACE,gBAAgB;IAClB;EACF;EACA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":["  .card {\n    \n    margin-inline: auto;\n    background-color: var(--color3);\n    padding: 2rem;\n    color: var(--color2);\n    text-align: left;\n    align-content: centers;\n  }\n  .offer{\n    margin-bottom: 2rem;\n    font-family: var(--ff--accent);\n  }\n  .column {\n    columns: 2;\n    margin-inline: auto;\n    width: min(90%, 90rem);\n  }\n\n\n  @media (max-width: 1040px) {\n    .column {\n      display: flex;\n      flex-direction: column;\n    }\n    .offer{\n      margin: 0.7rem 0;\n    }\n  }\n  @media (max-width : 400px) {\n    .offer{\n      font-size: .8rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Offer_card__ohrZE",
	"offer": "Offer_offer__EaBvD",
	"column": "Offer_column__yUXjy"
};
export default ___CSS_LOADER_EXPORT___;
