import React from "react";
import Sidebar from "./Sidebar.js";
import Navigation from "./Navigation.js";
import PropTypes from "prop-types";

function Navbar({ handlerToggle, pullLeftRight }) {
  return (
    <div className="navbar">
      <Sidebar
        pullLeftRight={pullLeftRight}
        handlerToggle={handlerToggle}
      ></Sidebar>
      <Navigation handlerToggle={handlerToggle} onChange={pullLeftRight} />
    </div>
  );
}

export default Navbar;
Navbar.propTypes = {
  handlerToggle: PropTypes.bool.isRequired,
  pullLeftRight: PropTypes.func.isRequired,
};
