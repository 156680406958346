import React, { useEffect } from "react";
import "./Footer.css";
import Aos from "aos";
import "aos/dist/aos.css";
import PhotoSwipGallery from "../utilities/PhotoSwipGallery";
import { Link } from "react-scroll";
import PropTypes from "prop-types";

function Footer({ handlerToggle, onChange }) {
  const navList = ["O mnie", "Usługi", "Oferta", "Kontakt"];
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <footer className="footer">
      <div className="main-part">
        <div className="footer-list-wrapper">
          <ul className="footer-list">
            <li
              className="footer-list-item"
              data-aos="fade-left"
              data-aos-once="true"
            >
              <p href="#" className="footer-list-link">
                support@relaxarium.com
              </p>
            </li>
            <li
              className="footer-list-item"
              data-aos="fade-right"
              data-aos-once="true"
            >
              <p href="#" className="footer-list-link">
                <b>Adres : </b>
                ul. Odrzańska 6a/4, Wrocław
              </p>
            </li>
            <li
              className="footer-list-item"
              data-aos="fade-left"
              data-aos-once="true"
            >
              <p href="#" className="footer-list-link">
                <b>Telefon : </b>
                +48 605 455 205
              </p>
            </li>
          </ul>
        </div>

        <div className="footer-list-wrapper">
          <ul className="footer-list">
            {navList.map((item, i) => {
              return (
                <li
                  className="footer-list-item"
                  data-aos="fade-left"
                  data-aos-once="true"
                  key={i}
                >
                  <div className="footer-list-link">
                    <Link
                      activeClass="active"
                      smooth
                      duration={2000}
                      spy
                      to={item}
                      onClick={handlerToggle ? onChange : ""}
                    >
                      {item}
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="footer-list-wrapper gallery">
          <PhotoSwipGallery id="my-gallery" />
        </div>
      </div>

      <div className="creator-part">
        <div className="copyright-text">
          <p>Copyright &copy; {new Date().getFullYear()} Relaxarium</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
Footer.propTypes = {
  handlerToggle: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
