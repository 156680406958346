import React from "react";
import Product from "./Product";
import PropTypes from "prop-types";

function Products({ productType }) {
  return (
    <div>
      <Product productType={productType} />
    </div>
  );
}

export default Products;

Products.propTypes = {
  productType: PropTypes.string.isRequired,
};
