import React from "react";
import Popup from "reactjs-popup";
import "./PopupPage.css";
import PropTypes from "prop-types";
import Products from "../products/Products";
import useWindowDimensions from "../../hooks/useWindowDimensions";
// import classes from  './Service.module.css';

function PopupPage({ title, imageSrc, productType }) {
  const { width } = useWindowDimensions();

  const contentStyle = {
    maxWidth: "100vw",
    width: "70%",
  };
  var styleImage = {
    background: `linear-gradient(to top ,  rgba(250, 151, 163, 0.6), rgba(250, 151, 163, .6)), url( ${imageSrc} ) center no-repeat`,
    backgroundSize: "cover",
  };
  return (
    <Popup
      trigger={
        <div className="container">
          <div className="center">
            {width > 400 && (
              <button className="btn">
                <svg
                  width="180px"
                  height="60px"
                  viewBox="0 0 180 60"
                  className="border"
                >
                  <polyline
                    points="179,1 179,59 1,59 1,1 179,1"
                    className="bg-line"
                  />
                  <polyline
                    points="179,1 179,59 1,59 1,1 179,1"
                    className="hl-line"
                  />
                </svg>
                <span>WIĘCEJ</span>
              </button>
            )}
            {width < 400 && (
              <div className="split">
                <div className="imgWrap">
                  <img className="serviceImg" src={imageSrc} alt={title} />
                </div>
                <div className="description">
                  <h1>{title}</h1>
                  {/* <p>{description}</p> */}
                </div>
              </div>
            )}
          </div>
        </div>
      }
      modal
      contentStyle={contentStyle}
    >
      {(close) => (
        <div className="modal">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="headerPopup" style={styleImage}>
            {" "}
            <h5>{title}</h5>{" "}
          </div>
          <div className="content">
            <Products productType={productType} />
          </div>
        </div>
      )}
    </Popup>
  );
}

export default PopupPage;

PopupPage.propTypes = {
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
};
