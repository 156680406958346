import "./App.css";
import React, { useState } from "react";
import Navbar from "./components/navbar/Navbar";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Services from "./components/services/Services";
import Offers from "./components/offers/Offers";


function App() {
  const [handlerToggle, setHandlerToggle] = useState(false);

  const pullLeftRight = () => {
    console.log(handlerToggle);
    setHandlerToggle(!handlerToggle);
  };
  return (
    <div className="App">
      <Navbar handlerToggle={handlerToggle} pullLeftRight={pullLeftRight} />
      <div className="context">
        <section id="O mnie">
          <Header />
        </section>
        <section id="Oferta">
          <Offers />
        </section>
        <section id="Usługi">
          <Services />
        </section>
        <section id="Kontakt">
          <Footer handlerToggle={handlerToggle} onChange={pullLeftRight} />
        </section>
      </div>
    </div>
  );
}

export default App;
