import React from "react";
import "./Navigation.css";
import NavigationItem from "./NavigationItem";
import PropTypes from "prop-types";

function Navigation({ handlerToggle, onChange }) {
  const navList = ["O mnie", "Oferta", "Usługi", "Kontakt"];

  return (
    <div className={`navigation ${handlerToggle ? "active" : ""}`}>
      <div className="navigation-header">
        <h1 className="navigation-heading">Relaxarium</h1>
      </div>
      <ul className="navigation-list">
        {navList.map((item, i) => {
          return (
            <NavigationItem
              label={item}
              key={i}
              onChange={onChange}
            ></NavigationItem>
          );
        })}
      </ul>
    </div>
  );
}

export default Navigation;
Navigation.propTypes = {
  handlerToggle: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
