import React from "react";
import classes from "./NavigationItem.module.css";
import PropTypes from "prop-types";
import { Link } from "react-scroll";

function NavigationItem({ label, onChange }) {
  return (
    <li className={classes.navigationItem}>
      <div className={classes.navigationLink}>
        <Link
          activeClass={classes.active}
          smooth
          duration={2000}
          spy
          to={label}
          onClick={onChange}
        >
          {label}
        </Link>
      </div>
    </li>
  );
}

export default NavigationItem;
NavigationItem.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
